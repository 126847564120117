import React from 'react';
import PropTypes from 'prop-types';
import { withSize } from 'react-sizeme';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GeneralContent from '../components/GeneralContent';
import ContactStyled from '../components/pageStyles/contact-styles';
import Image from '../components/ui/Image';
import PullQuoteBox from '../components/PullQuoteBox';
import Exclamation from '../components/ui/SVGs/Exclamation';
import useGlobal from '../store';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from 'react-html-parser';
import backgroundImage from '../images/green america/home page/hero.jpg';
import HtmlParser from 'react-html-parser';

const ContactPage = ({ path, size }) => {
    const [globalState, globalActions] = useGlobal();
    const defaultSection = [{ main: {}, detail: [] }, { main: {}, detail: [] }];
    const defaultImageSection = [{ main: {}, detail: [] }];
   
    
   const {
        contact: {
            contacts: [
                { main: contact_left_main, detail: contact_left_detail },
                { main: contact_right_main, detail: contact_right_detail },
            ] = defaultSection,
            contact_us_image: [
                { main: contact_image_main, detail: contact_image_detail },
            ] = defaultImageSection,
        },
    } = globalState;
  
 
    const contactImageSrc =
        contact_image_main?.images &&
        contact_image_main?.images.length > 0 &&
        contact_image_main?.images[0]?.file;

    return (
        <Layout path={path}>
            <SEO title="Contact" />
            <ContactStyled
                contactImageSrc={contactImageSrc}
                title={contactImageSrc?.title}
            >
                <div className="contact-maincontainer">
                    <div className="contactcards-container">
                        <div className="contactcards-flexcontainer">
                            <div className="contactcard-left"> 
                            {contact_left_detail &&
                                    contact_left_detail.map((item) => {
                                        return (
                                            <div className="contact-card" key={item._metadata.uid}>
                                                <div className="contact-header">
                                                    {item.heading
                                                        ? item.heading.text
                                                        : ''}
                                                </div>
                                                <div className="contact-content">
                                                    {item.body
                                                        ? ReactHtmlParser(
                                                              item.body.richtext
                                                          )
                                                        : ''}
                                                </div>
                                            </div>
                                        );
                                    })} 
                             </div>
                            <div className="contactcard-right">  
                            {contact_right_detail &&
                                    contact_right_detail.map((item) => {
                                        return (
                                            <div className="contact-card" key={item._metadata.uid}>
                                                <div className="contact-header">
                                                    {item.heading
                                                        ? item.heading.text
                                                        : ''}
                                                </div>
                                                <div className="contact-content">
                                                    {item.body
                                                        ? ReactHtmlParser(
                                                              item.body.richtext
                                                          )
                                                        : ''}
                                                </div>
                                            </div>
                                        );
                                })} 
                            
                            </div>
                        </div>
                    </div>
                </div>
            </ContactStyled>
        </Layout>
    );
};

ContactPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(ContactPage);
