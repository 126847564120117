import styled from 'styled-components';

const ContactStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 768px) {
        /* padding-top: 100px; */
    }
    .contact-general-content {
        @media screen and (max-width: 360px) {
            .section-header-copy,
            .eyebrow-text,
            .section-header {
                padding-left: 17px;
                padding-right: 17px;
            }
        }
    }
    .contact-maincontainer {
        position: relative;
        margin: auto;
        background-image: url(${(props) => props.contactImageSrc?.url});
        background-position: center bottom;
        background-size: cover;
        width: 100%;
        height: 940px;

        @media screen and (min-width: 1024px) {
            background-position: center;
        }
        @media screen and (min-width: 1440px) {
            margin: 0;
        }
    }

    .contact-image {
    }

    .contactcards-container {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .contactcards-flexcontainer {
        display: flex;
        width: 1032px;
        background: white;
        flex-direction: column;
        margin: auto;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }
        @media screen and (min-width: 1440px) {
            max-width: 1260px;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            flex-direction: row;
            width: 750px;
        }
        @media screen and (max-width: 600px) {
            width: 300px;
            word-wrap: break-word;
            height: 800px;
            overflow: auto;
        }
    }

    .contactcard-left,
    .contactcard-right {
        display: flex;
        flex: 1;
        background: white;
        padding: 45px;
        flex-direction: column;

        @media screen and (min-width: 768px) {
            width: 725px;
            padding: 3% 5%;
        }
        @media screen and (max-width: 700px) {
            .contact-card {
                width: 100%;
            }
        }
    }

    .contactcard-left {
        @media screen and (max-width: 700px) {
            padding-bottom: 0px;
        }
    }

    .contactcard-right {
        @media screen and (max-width: 700px) {
            padding-top: 0px;
        }
    }

    .contact-header {
        color: ${(props) => props.theme.colors.secondary};
        font: 700 0.875rem ${(props) => props.theme.fonts[0]}, sans-serif;
        letter-spacing: 1.68px;
        line-height: 1.875rem;
        text-align: left;
        text-transform: uppercase;
    }

    .contact-content {
        color: ${(props) => props.theme.colors.texts.capeCod};
        font: 18px ${(props) => props.theme.fonts[0]};
        letter-spacing: 0;
        line-height: 2rem;
        margin-bottom: 31px;
        text-align: left;
    }

    .emergency-contact-content {
        height: 39px;
        text-align: left;
        color: ${(props) => props.theme.colors.secondary};
        font: 700 1.75rem ${(props) => props.theme.fonts[0]}, sans-serif;
        letter-spacing: 0;
        line-height: 2.5rem;
    }

    .exclamation-icon {
        width: 60px;
        height: 60px;
        display: block;
        margin: 10px;
    }
`;

export default ContactStyled;
