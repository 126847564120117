import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Anchor from './ui/Controls/Anchor';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from 'react-html-parser';
const GeneralContentStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 307px;
    margin: auto;
    justify-content: ${(props) => props.styles.textAlign};
    align-items: ${(props) => props.styles.textAlign};

    @media screen and (min-width: 768px) {
        width: 54.5%;
    }
    .eyebrow-text {
        display: ${(props) => props.styles.eyebrow.display ?? "flex"};
        flex-direction: column;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.texts.capeCod};
        font: bold 1rem ${(props) => props.theme.fonts[0]};
        letter-spacing: 0.12rem;
        line-height: 1.875rem;
        justify-content: ${(props) => props.styles.eyebrow.textAlign};
        align-items: ${(props) => props.styles.eyebrow.textAlign};
        :after {
            display: block;
            content: ' ';
            width: 90px;
            margin: 11px 0 23px 0;
            border-bottom: 4px solid ${(props) => props.theme.colors.secondary};
            @media screen and (max-width: 768px) {
                width: 51.05px;
                margin: 11px 0 23px 0;
            }
        }
    }
    .section-header {
        display: flex;
        flex-direction: column;
        width: 745px;
        color: ${(props) => props.theme.colors.primary};
        font: 300 3.125rem ${(props) => props.theme.fonts[0]};
        letter-spacing: -0.055rem;
        line-height: 3.75rem;
        margin: 0 0 29px 0;
        text-align: ${(props) => props.styles.header.textAlign};
        @media screen and (max-width: 768px) {
            width: 307px;
            font: 300 1.875rem ${(props) => props.theme.fonts[0]};
            margin: 0 0 15px 0;
            text-align: ${(props) => props.styles.header.mobile.textAlign};
        }
    }
    .section-header-copy {
        width: 745px;
        color: #4a4f53;
        font: 1.25rem ${(props) => props.theme.fonts[1]};
        letter-spacing: 0;
        line-height: 2.625rem;
        margin-bottom: ${(props) => props.styles.copy.marginBottom ?? "56px"};
        text-align: ${(props) => props.styles.copy.textAlign};
        @media screen and (max-width: 768px) {
            width: 307px;
            font: 1.125rem ${(props) => props.theme.fonts[1]};
            line-height: 2.378rem;
            margin-bottom: ${(props) => props.styles.copy.marginBottom ?? "34px"};
            text-align: ${(props) => props.styles.copy.mobile.textAlign};
        }
        @media only screen and (width: 768px) {
            width: 100%;
        }
    }
    a {
        color: ${(props) => props.theme.colors.buttons.textLink};
        font: bold 0.875rem ${(props) => props.theme.fonts[2]};
        letter-spacing: 0.065rem;
        line-height: 2.125rem;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
    }
`;
export default function GeneralContent({
    eyebrowText,
    sectionHeader,
    sectionHeaderCopy,
    linkText,
    linkUrl,
    generalContentStyles,
    isExternal,
}) {
    return (
        <GeneralContentStyled styles={generalContentStyles}>
            <p className="eyebrow-text">{eyebrowText}</p>
            {sectionHeader ? (
                <h1 className="section-header">{sectionHeader}</h1>
            ) : null}
            {sectionHeaderCopy ? (
                <div className="section-header-copy">
                    {ReactHtmlParser(sectionHeaderCopy)}
                </div>
            ) : null}
            {linkText && linkUrl ? (
                <Anchor
                    linkURL={linkUrl}
                    text={linkText}
                    isExternal={isExternal}
                />
            ) : null}
        </GeneralContentStyled>
    );
}
GeneralContent.defaultProps = { isExternal: false };

GeneralContent.propTypes = {
    eyebrowText: PropTypes.string,
    sectionHeader: PropTypes.string,
    sectionHeaderCopy: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    generalContentStyles: PropTypes.object.isRequired,
};
