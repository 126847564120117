import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import DotGridImg from '../../images/dot-grid.png';

const ImageStyled = styled.div`
    position: relative;
    width: 100%;
    &.heroImage {
        overflow: hidden;
        & img {
            height: 317px;
        }
    }
    & > img {
        width: 100%;
    }

    .dot-grid {
        position: absolute;
        left: calc(50% - 25px);
        bottom: -15px;
        margin-top: -65px;
        width: 54px;
    }

    @media screen and (max-width: 430px) {
        &.heroImage > img {
            width: 130%;
            margin-left: -20%;
        }
    }
    @media only screen and (width: 768px) {
        &.heroImage {
            margin-top: 0 !important;
        }
    }
    @media screen and (min-width: 768px) {
        &.heroImage {
            & img {
                width: 100%;
                height: auto;
            }
        }
        .dot-grid {
            /* position: absolute;
        left: -55px;
        bottom: -40px;
        margin-top: 0px;
        width: 114px; */
        }
    }

    &.bullets--green {
        & > img {
            margin-top: 1rem;
        }
    }

    @media screen and (min-width: 647px) and (max-width: 1024px) {
        & .safety-img {
            width: 641px;
        }
    }
`;

export default function Image(props) {
    const {
        hasLink,
        targetLink,
        imgSrc,
        width,
        height,
        className,
        showDotGrid = false,
    } = props;

    return (
        <ImageStyled
            className={className}
            style={{ width: `${width}`, height: `${height}` }}
        >
            {hasLink ? (
                <Link to={targetLink}>
                    <img
                        src={imgSrc}
                        style={{ width: `${width}`, height: `${height}` }}
                    />
                </Link>
            ) : (
                <img
                    src={imgSrc?.url}
                    style={{ width: `${width}`, height: `${height}` }}
                    className="safety-img"
                    alt={imgSrc?.description}
                    title={imgSrc?.title}
                />
            )}
            {showDotGrid ? <img className="dot-grid" src={DotGridImg} /> : null}
        </ImageStyled>
    );
}

Image.propTypes = {
    hasLink: PropTypes.bool,
    targetLink: PropTypes.string,
    imgSrc: PropTypes.string,
};

Image.defaultProps = {
    hasLink: false,
};
